
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'130px'"
      >
        <el-form-item label="申请者单位" prop="applicationCompanyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.applicationCompanyId"
            size=""
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="包括下级单位" prop="includeSubordinate">
          <el-switch 
            v-model="formModel.includeSubordinate"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>                    
        <el-form-item label="申请者身份" prop="applicationRoleId">
          <el-select 
            v-model="formModel.applicationRoleId"
            filterable
            multiple
            placeholder="请选择"
            style="width: 280px"
          >
            <el-option
              v-for="role in applicationRoleResult"
              :key="role.id"
              :label="role.personRoleName"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核人单位" prop="examineCompanyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.examineCompanyId"
            size=""
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="审核人身份" prop="examineRoleId">
          <el-select
            v-model="formModel.examineRoleId"
            filterable
            placeholder="请选择"
            style="width: 280px"
          >
            <el-option
              v-for="role in examineRoleResult"
              :key="role.id"
              :label="role.personRoleName"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批类型" prop="type">
          <el-select
            v-model="formModel.type"
            filterable
            placeholder="请选择"
            style="width: 280px"
          >
            <el-option
              v-for="result in examineTypeResult"
              :key="result.value"
              :label="result.name"
              :value="result.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批等级" prop="level">
          <el-input-number
            v-model="formModel.level"
            placeholder="请输入审批等级"
            style="width: 300px"
            :min="1"
          ></el-input-number>
          <span>&nbsp;表示第几轮审批</span>
        </el-form-item>
        <el-form-item label="请假超过天数" prop="days" v-if="formModel.type=='4'">
          <el-input-number
            v-model="formModel.days"
            placeholder="请输入天数"
            style="width: 300px"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="备注信息" prop="remark">
          <el-input
            v-model="formModel.remark"
            placeholder="请输入备注信息"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import applicationExamineApi from "@/api/base/applicationExamine";
import companyInfoApi from "@/api/base/companyInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import personRoleInfoApi from "@/api/base/personRoleInfo";

import SelectTree from "@/components/SelectTree";

export default {
  components: {
    "el-select-tree": SelectTree,
  },
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        applicationRoleId: [
          { required: true, message: "申请者角色不能为空", trigger: "blur" },
        ],
        applicationCompanyId: [
          { required: true, message: "申请者公司不能为空", trigger: "blur" },
        ],
        examineRoleId: [
          { required: true, message: "审核人角色不能为空", trigger: "blur" },
        ],
        examineCompanyId: [
          { required: true, message: "审核人公司不能为空", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择审批类型", trigger: "blur" },
        ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      applicationRoleResult: [],
      examineRoleResult: [],
      companyResult: [],
      examineTypeResult: [],
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    var formData = new FormData();
    formData.append("catalogName", "审批类型");
    dataDictionaryApi.findByCatalogName(formData).then((response) => {
      var jsonData = response.data;
      this.examineTypeResult = jsonData.data;
    });
  },
  watch: {
    "formModel.applicationCompanyId": function (val, oldval) {
      if (val != null && val != "") {
        var self = this;
        var formData = new FormData();
        formData.append("companyId", val);
        personRoleInfoApi.listByCompanyId(formData).then(function (response) {
          var jsonData = response.data;
          if (jsonData.result) {
            if (jsonData.data != null && jsonData.data != "") {
              self.applicationRoleResult = jsonData.data;

              // applicationCompanyId
              // applicationRoleId
              // examineCompanyId
              // examineRoleId
            }
          }
        });
      }
    },
    "formModel.examineCompanyId": function (val, oldval) {
      if (val != null && val != "") {
        var self = this;
        var formData = new FormData();
        formData.append("companyId", val);
        personRoleInfoApi.listByCompanyId(formData).then(function (response) {
          var jsonData = response.data;
          if (jsonData.result) {
            if (jsonData.data != null && jsonData.data != "") {
              self.examineRoleResult = jsonData.data;

              // applicationCompanyId
              // applicationRoleId
              // examineCompanyId
              // examineRoleId
            }
          }
        });
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            var submitModel = JSON.parse(JSON.stringify(self.formModel));

            if (submitModel.applicationRoleId != null) {
              var applicationRoleId = submitModel.applicationRoleId.join(",");
              submitModel.applicationRoleId = applicationRoleId;
            }

            if (id == null || id.length == 0) {
              return applicationExamineApi.add(submitModel);
            } else {
              return applicationExamineApi.update(submitModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return applicationExamineApi.create();
      } else {
        return applicationExamineApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          var applicationRoleId = jsonData.data.applicationRoleId;

          if (applicationRoleId != null) {
            jsonData.data.applicationRoleId = applicationRoleId.split(",");
          }

          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>