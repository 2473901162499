<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/applicationExamine">审批角色管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-row>
        <el-form-item label="申请者公司" prop="applicationCompanyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="queryModel.applicationCompanyId"
            size="mini"
            height="200"
          ></el-select-tree
          >&nbsp;
          <el-checkbox v-model="queryModel.applicationSubordinate"
            >是否包含下级单位</el-checkbox
          >
        </el-form-item>
        <el-form-item label="申请者角色" prop="applicationRoleId">
          <el-select
            v-model="queryModel.applicationRoleId"
            filterable
            clearable
            placeholder="请选择"
            style="width: 200"
            size="mini"
          >
            <el-option
              v-for="role in applicationRoleResult"
              :key="role.id"
              :label="role.personRoleName"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批类型" prop="type">
          <el-select
            v-model="queryModel.type"
            filterable
            size="mini"
            clearable
            placeholder="请选择"
            style="width: 200px"
          >
            <el-option
              v-for="result in examineTypeResult"
              :key="result.value"
              :label="result.name"
              :value="result.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="审核人公司" prop="examineCompanyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="queryModel.examineCompanyId"
            size="mini"
            height="200"
          ></el-select-tree
          >&nbsp;
          <el-checkbox v-model="queryModel.examineSubordinate"
            >是否包含下级单位</el-checkbox
          >
        </el-form-item>
        <el-form-item label="审批等级" prop="level">
          <el-input
            size="mini"
            v-model="queryModel.level"
            style="width: 80px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
            >查询</el-button
          >&nbsp;
          <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
            >重置</el-button
          >&nbsp;
        </el-form-item>
      </el-row>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="applicationCompanyName"
        label="申请者单位"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="applicationRoleName"
        label="申请者身份"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="includeSubordinate"
        label="是否包括下级单位"
        width="150"
      >
        <template slot-scope="{row}">
            <el-switch 
                v-model="row.includeSubordinate"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="handleUpdate(row)"
              >
            </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="typeName"
        label="审批类型"
        width="100"
      ></el-table-column>
      <el-table-column prop="level" label="审批等级" width="100">
        <template slot-scope="{ row }">
          <span v-if="row.level != '' && row.level != null">lv.{{ row.level }}</span>
          <span v-else>{{ row.level }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="examineCompanyName"
        label="审核人单位"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="examineRoleName"
        label="审核人身份"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="days"
        label="请假超过天数"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注信息"
      ></el-table-column>
      <el-table-column label="操作" width="130" fixed="right">
        <template slot-scope="{ row }">
          <el-row>
            <el-col :span="12">
              <el-link type="primary" @click="handleEdit(row)">编辑</el-link>
            </el-col>
            <el-col :span="12">
              <el-link type="danger" @click="handleDelete(row)">删除</el-link>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <applicationExamine-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></applicationExamine-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import ApplicationExamineDetail from "./applicationExamine-detail";
import applicationExamineApi from "@/api/base/applicationExamine";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import personRoleInfoApi from "@/api/base/personRoleInfo";
import companyInfoApi from "@/api/base/companyInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

import SelectTree from "@/components/SelectTree";

export default {
  name: "baseApplicationExamineList",
  data() {
    var self = this;

    return {
      queryModel: {
        applicationRoleId: "",
        applicationCompanyId: "",
        applicationSubordinate: false,
        examineRoleId: "",
        examineCompanyId: "",
        examineSubordinate: false,
        type:"",
        level:""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      companyResult: [],
      applicationRoleResult:[],
      examineTypeResult:[]
    };
  },
  watch: {
    "queryModel.applicationCompanyId": function (val, oldval) {
      if (val != null && val != "") {
        var self = this;
        var formData = new FormData();
        formData.append("companyId", val);
        personRoleInfoApi.listByCompanyId(formData).then(function (response) {
          var jsonData = response.data;
          if (jsonData.result) {
            if (jsonData.data != null && jsonData.data != "") {
              self.applicationRoleResult = jsonData.data;

              // applicationCompanyId
              // applicationRoleId
              // examineCompanyId
              // examineRoleId
            }
          }
        });
      }
    }
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    var formData = new FormData();
    formData.append("catalogName", "审批类型");
    dataDictionaryApi.findByCatalogName(formData).then((response) => {
      var jsonData = response.data;
      this.examineTypeResult = jsonData.data;
    });
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("applicationRoleId", self.queryModel.applicationRoleId);
      if (self.queryModel.applicationCompanyId == null) {
        self.queryModel.applicationCompanyId = "";
      }
      formData.append(
        "applicationCompanyId",
        self.queryModel.applicationCompanyId
      );
      formData.append(
        "applicationSubordinate",
        self.queryModel.applicationSubordinate
      );
      formData.append("examineRoleId", self.queryModel.examineRoleId);
      if (self.queryModel.examineCompanyId == null) {
        self.queryModel.examineCompanyId = "";
      }
      formData.append("examineCompanyId", self.queryModel.examineCompanyId);
      formData.append("examineSubordinate", self.queryModel.examineSubordinate);
      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      if (self.queryModel.type != null) {
        formData.append("type", self.queryModel.type);
      }
      if (self.queryModel.level != null) {
        formData.append("level",self.queryModel.level);
      }
      

      applicationExamineApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          applicationExamineApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        applicationExamineApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleUpdate(row){
        applicationExamineApi.update(row).then((response)=>{
          var jsonData = response.data;

          if(jsonData.result){
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          }
          else{            
            this.$message({
              type: "error",
              message: "修改失败!" + jsonData.data.message,
            });
          }
        });
    }
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "applicationExamine-detail": ApplicationExamineDetail,
    "el-select-tree": SelectTree,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>